<template>
    <div class="about-content">
        <div class="about-banner">
            <div class="about-banner-main">
                <h3>关于我们</h3>
                <p>基于“互联网+医疗”的综合医学服务机构，专注于泌尿男科领域</p>
            </div>
        </div>

        <div class="about-swiper">
            <swiper :options="swiperOption">
                <swiper-slide v-for="i in 1" :key="i">
                    <div class="about-intro">
                        <div class="about-intro-img">
                            <img src="https://image.ysxxlm.cn/public/uploads/ueditor/image/20210616/1623807946919498.jpg"/>
                        </div>
                        <div class="about-intro-right">
                            <div class="about-intro-desc">湖南优安医学科技有限公司是一家基于“互联网+医疗”的综合医学服务机构，专注于泌尿男科领域。依托泌尿外科医生学习联盟强大的学术资源，以赋能泌尿男科医护为核心，推动泌尿男科产业发展，助力健康中国2030。</div>
                            <div class="about-intro-descimg">
                                <img src="https://image.ysxxlm.cn/public/uploads/ueditor/image/20190505/1557045891557842.png"/>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>



        <div class="about-stat">
            <div class="about-stat-main">
                <plate-title title="国内最大、最活跃的泌尿男科医生平台" desc="LARGEST NEW MEDIA PLATFORM FOR UROLOGY"/>
                <div class="about-stat-list">
                    <div class="about-stat-item">
                        <!--<h4>{{ about.followersNum.num }}+</h4>-->
                        <h4><countTo :startVal='count.followersNum.to' :endVal='count.followersNum.en' :duration='5000'></countTo>+</h4>
                        <p>{{ about.followersNum.text }}</p>
                    </div>
                    <div class="about-stat-item">
                        <h4><countTo :startVal='count.followersRatio.to' :endVal='count.followersRatio.en' :duration='5000'></countTo>%+</h4>
                        <p>{{ about.followersRatio.text }}</p>
                    </div>
                    <div class="about-stat-item">
                        <h4><countTo :startVal='count.source.to' :endVal='count.source.en' :duration='5000'></countTo>%+</h4>
                        <p>{{ about.source.text }}</p>
                    </div>
                    <div class="about-stat-item">
                        <h4><countTo :startVal='count.articleNum.to' :endVal='count.articleNum.en' :duration='5000'></countTo>+</h4>
                        <p>{{ about.articleNum.text }}</p>
                    </div>
                    <div class="about-stat-item">
                        <h4><countTo :startVal='count.coupeNum.to' :endVal='count.coupeNum.en' :duration='5000'></countTo>+</h4>
                        <p>{{ about.coupeNum.text }}</p>
                    </div>
                    <div class="about-stat-item">
                        <h4><countTo :startVal='count.session.to' :endVal='count.session.en' :duration='5000'></countTo>+</h4>
                        <p>{{ about.session.text }}</p>
                    </div>
                    <!--<div class="about-stat-item">
                        <h4><countTo :startVal='count.groupNum.to' :endVal='count.groupNum.en' :duration='5000'></countTo>+</h4>
                        <p>{{ about.groupNum.text }}</p>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="about-constitute">
            <plate-title title="学习联盟成员结构" desc="THE COMPOSITION OG LEARNING ALLIANCE MEMBERS"/>
            <div class="about-constitute-info">
                学习联盟成员主要是来自全国各地二级以上公立医院泌尿外科医生。绝大部分是目前泌尿外科的中坚力量，也是具有强烈学习愿望的主治和副主任医生。 会员来自全国各个省市：Top15省份有山东省、湖南省、河北省、安徽省、江苏省、河南省、浙江省、湖北省、广东省、陕西省、江西省、黑龙江省、辽宁省、北京市、福建省。
            </div>
            <div class="about-constitute-swiper">
                <!--<swiper :options="swiperOption2">
                    <swiper-slide><img src="../../assets/img/about/tj1@2x.png"/></swiper-slide>
                    <swiper-slide><img src="../../assets/img/about/tj2@2x.png"/></swiper-slide>
                    <swiper-slide><img src="../../assets/img/about/tj3@2x.png"/></swiper-slide>
                    <swiper-slide><img src="../../assets/img/about/tj4@2x.png"/></swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>-->

                <el-carousel :interval="4000" type="card" height="510px">
                    <el-carousel-item><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/tj1@2x.png"/></el-carousel-item>
                    <el-carousel-item><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/tj2@2x.png"/></el-carousel-item>
                    <el-carousel-item><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/tj3@2x.png"/></el-carousel-item>
                    <el-carousel-item><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/tj4@2x.png"/></el-carousel-item>
                </el-carousel>
            </div>
        </div>


        <div class="about-promotion">
            <div class="about-promotion-main">
                <plate-title title="品牌与市场多种推广方式" desc="BRAND AND MARKET RPOMOTION" :about="true"/>
                <div class="about-promotion-swiper">
                    <swiper :options="swiperOption3">
                        <swiper-slide v-for="(item,index) in list" :key="index" class="about-promotion-swiper-item">
                            <div class="about-promotion-item">
                                <div class="about-promotion-front">
                                    <img :src="item.img"/>
                                    <p>{{ item.name }}</p>
                                </div>
                                <div class="about-promotion-back">
                                    <h3>{{ item.name }}</h3>
                                    <p>{{ item.info }}</p>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev" slot="button-prev"><i class="iconfont icon-07jiantouxiangzuofill"></i></div>
                    <div class="swiper-button-next" slot="button-next"><i class="iconfont icon-07jiantouxiangyoufill"></i></div>
                </div>
            </div>
        </div>


        <div class="about-bottom">
            <div class="about-bottom-main">
                <div class="about-bottom-info">
                    <div class="about-bottom-logo">
						<!-- <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/logo@2x-2.png"> -->
						<img src="~@/assets/img/logo@3x.png">
						</div>
                    <ul>
                        <li><i class="iconfont icon-dizhi"></i>联系地址：长沙市岳麓区达美美立方3栋一单元510室</li>
                        <li><i class="iconfont icon-dianhua"></i>联系电话：0731-85522232</li>
                        <li><i class="iconfont icon-hezuo1"></i>商务合作：15757122301 / ylx@ysxxlm.com</li>
                    </ul>
                </div>
                <div class="about-bottom-ewm">
                    <ul>
                        <li>
                            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/erweima.jpg"/>
                            <p>优安医生</p>
                        </li>
                        <li>
                            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/mp.jpg"/>
                            <p>泌尿科那点事儿</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    import countTo from 'vue-count-to';
    export default {
        data() {
            return {
                count: {
                    followersNum: {
                        to: 0,
                        en: 0  // 45000+
                    },
                    followersRatio: {
                        to: 0,
                        en: 0  // 80%+
                    },
                    source: {
                        to: 0,
                        en: 0  // 97%+
                    },
                    articleNum: {
                        to: 0,
                        en: 0  // 1630+
                    },
                    session: {
                        to: 0,
                        en: 0  // 350
                    },
                    groupNum: {
                        to: 0,
                        en: 0  // 400+
                    },
                    coupeNum: {
                        to: 0,
                        en: 0  // 400+
                    }
                },
                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination',
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + (index + 1) + '</span>';
                        },
                        clickable: true
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true // 手动切换之后继续自动轮播
                    },
                    loop: true
                },
                swiperOption2: {
                    pagination: {
                        el: '.swiper-pagination'
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                    },
                    loop: true,
                    loopedSlides: 3,
                    effect: "coverflow",
                    spaceBetween: '-50%',
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 0,
                        depth: 0,
                        modifier: 0,
                        slideShadows: false
                    }
                },
                swiperOption3: {
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.about-promotion-swiper .swiper-button-next',
                        prevEl: '.about-promotion-swiper .swiper-button-prev',
                    },
                    slidesPerView: 4,
                    spaceBetween: 26
                },
                list: [
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/01@2x.png",
                        name: "《医师报》泌尿外科专栏",
                        info: "大咖访谈、学术前沿、手术理念，专家培养，提升品牌影响力最佳方式"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/02@2x.png",
                        name: "公众号推文",
                        info: "新闻、软文、硬广，医师报与联盟官方公众号"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/03@2x.png",
                        name: "社群传播",
                        info: "实现泌尿外科领域的最广度覆盖"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/04@2x.png",
                        name: "培训直播",
                        info: "专家讲课、手术视频讲解，每周两次，平均在线泌尿外科医生3000人，每次都是全国会议级规模"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/05@2x.png",
                        name: "手术直播",
                        info: "大咖操刀，线上直播，医生足不出户学习最先进的手术理念与手法，以及器械使用方法"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/06@2x.png",
                        name: "学术活动",
                        info: "全国性、区域性学术活动组织，讲者邀请、医生报名，会前、会中、会后多次的线上互动、推广，线下小会议，线上大影响"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/07@2x.png",
                        name: "市场调研",
                        info: "覆盖面广，同时可以根据需求精准定位调研对象，快速、有效回收问卷"
                    },
                    {
                        img: "https://image.ysxxlm.cn/ysxxlm/pc/static/img/about/08@2x.png",
                        name: "新品发布",
                        info: "让新产品信息快速覆盖泌尿外科男科医生群体"
                    }
                ]
            }
        },
        components: { countTo },
        computed: {
            about() {
                return this.$store.state.about
            }
        },
        mounted() {
            document.documentElement.scrollTop = 0
            window.addEventListener("scroll",this.showbtn,true);
        },
        methods: {
            showbtn(){
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop>300) {
                    this.count.followersNum.en = this.about.followersNum.num;
                    this.count.followersRatio.en = this.about.followersRatio.num;
                    this.count.source.en = this.about.source.num;
                }
                if(scrollTop>350){
                    this.count.articleNum.en = this.about.articleNum.num;
                    this.count.session.en = this.about.session.num;
                    this.count.groupNum.en = this.about.groupNum.num;
                    this.count.coupeNum.en = this.about.coupeNum.num;
                }
            },
        }
    }
</script>

<style scoped>

</style>
